// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'id'
  }, {
    prop: 'online',
    back: true
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'description',
    span: 24
  }, {
    prop: 'description_en',
    span: 24
  }, {
    prop: 'bundle_guide',
    span: 24
  }, {
    prop: 'bundle_guide_en',
    span: 24
  }, {
    prop: 'bundle_policy',
    span: 24
  }, {
    prop: 'bundle_policy_en',
    span: 24
  }, {
    prop: 'bundle_value_f'
  }, {
    prop: 'price_f'
  }, {
    prop: 'expiration_in_days'
  }, {
    prop: 'created_at'
  }]
}
